
import { Link as PrismicLink } from 'prismic-reactjs'
import { linkResolver } from './linkResolver'


export const getLink = ( link ) => {
    let finalLink = link;

    if ( link._meta ) {
        finalLink = link._meta;
        finalLink.link_type = 'Document';
    }

    if ( link._linkType === 'Link.web' ) return link.url;

    link = PrismicLink.url(finalLink, linkResolver);
    return link;
}
