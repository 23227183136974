import React from 'react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer'
import { getColumnClass } from '../../utils/columns'
import './index.scss';

export default (props) => {

    let layout = <></>
    const headerLayout = props.layout

    let styles = {}
    if ( props.text_align ) {
        styles.textAlign = props.text_align
    }

    const title = (
        <div class="o-header" style={styles}>
            {RichText.render(props.title, null, htmlSerializer)}
        </div>
    )

    const content = (
        <div className="o-content">
            {RichText.render(props.content)}
        </div>
    )

    layout = (
        <>
            <div className="pure-g" data-custom-line={props.title_line ? props.title_line.toLowerCase() : ''}>
                <div className={getColumnClass('1', props.title_column_width, '1')}>{title}</div>
            </div>

            {props.content && RichText.asText(props.content) ? (
                    <div class="pure-g">
                        <div className={getColumnClass('1', props.content_column_width, '1')}>
                            {content}
                        </div>
                    </div>
                ) : <></>
            }
        </>
    )

    if ( headerLayout === 'Aligned' ) {
        layout = (
            <div className="pure-g aligned-header">
                <div className={getColumnClass('1', props.title_column_width, '1-2')}>{title}</div>
                <div className={getColumnClass('1', props.title_column_width, '1-2')}>{content}</div>
            </div>
        )
    }

    return (
        <div class="section-header">
            {layout}
        </div>
    )
}
