import React from 'react';
import moment from 'moment';

export default (props) => {

  return props.show_author && props && props.person ? (
      <div class="c-author-box">
        {props.person.image ?
          <div class="c-author-box--image" style={{ backgroundImage: `url(${props.person.image.url})` }}></div>
          : <></>
        }

        <div>
          <p class="c-author-box--author">{props.person.name}</p>

          {props.time_to_read ?
            <p class="c-author-box--details">{moment(props.date).format('MMM DD')} - {props.time_to_read} read</p>
            : <p class="c-author-box--details">{props.date}</p>
          }
        </div>
      </div>
    )  : <></>
}
