import { graphql } from "gatsby"
import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { getCursorFromDocumentIndex } from '@prismicio/gatsby-source-prismic-graphql'
import { query as ArticleFragment } from '../queries/article'
import ArticleMeta from '../components/Author'
import Block from '../components/Block'
import ColumnBox from '../components/ColumnBox'
import ContentBlock from '../components/TextImage'
import Layout from '../components/layout'


export const query = graphql`
  query BlogQuery($id: String!, $uid: String!, $lang: String!) {

    ...HeaderNavFragment
    ...FooterNavFragment
    ...SettingsFragment

    random: prismic {
      allPages(lang: $lang, uid: "404-page") {
        edges {
          node {
            _meta {
              id
            }
          }
        }
      }
    }

    blogListing: prismic {
      allBlog_listings(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            meta_title
            meta_description
            recent_heading
            more_heading
            assets_heading
            _meta {
              id
              uid
              lang
              type

              alternateLanguages {
                id
                lang
              }
            }

            parent {
              ... on PRISMIC_Page {
                _linkType

                _meta {
                  id
                  uid
                  lang
                  type
                }
              }
            }

            social {
              ... on PRISMIC_Blog_listingSocialGeneral_card {
                label
                type

                primary {
                  description
                  image
                  title
                }
              }

              ... on PRISMIC_Blog_listingSocialTwitter_card {
                label
                type

                primary {
                  card_type
                  description
                  image
                  title
                  twitter_handle
                }
              }
            }
          }
        }
      }
    }

    latestArticle: prismic {
      allArticles(sortBy: date_DESC, lang: $lang, where: { parent: $id }) {
        ...Article
      }
    }

    recentArticles: prismic {
      allArticles(sortBy: date_DESC, lang: $lang, first: 4, where: { parent: $id }) {
        ...Article
      }
    }

    moreArticles: prismic {
      allArticles(sortBy: date_DESC, lang: $lang, first: 10, where: { parent: $id }) {
        totalCount
        ...Article
      }
    }
  }
`


export const moreArticlesQuery = `
  query LoadMoreQuery($id: String, $lang: String, $first: Int = 6, $last: Int, $after: String, $before: String) {
    prismic {
      allArticles(sortBy: date_DESC, lang: $lang, first: $first, last: $last, after: $after, before: $before, where: { parent: $id }) {
        ...Article

        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`


export default ({ pageContext, data, location, prismic }) => {

  const doc = data.blogListing.allBlog_listings.edges.slice(0, 1).pop();
  if ( !doc ) return null;

  const didMountRef = useRef(false);

  // start at document index 4 (because of the ones above "more articles")
  const [page, setPage] = React.useState(4);
  const [moreArticles, setMoreArticles] = React.useState(data.moreArticles.allArticles.edges.slice(4));
  const [forcePage, setForcePage] = React.useState(0);


  const handlePageClick = (data) => {
      let itemStart = 4 + (data.selected * 6) - 1;
      setPage(itemStart);
      scrollTo('#more-articles');
  }


  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    prismic.load({
        variables: { after: getCursorFromDocumentIndex(page) },
        query: moreArticlesQuery, // (optional)
        fragments: [ArticleFragment], // (optional)
    }).then(res => setMoreArticles(res.data.allArticles.edges))

  }, [page]);


  let blogListing = doc.node;
  let latestArticle = data.latestArticle.allArticles.edges.slice(0, 1).pop();

  if ( !blogListing ) return null;

  if ( latestArticle ) latestArticle = latestArticle.node;
  if ( latestArticle && latestArticle.parent ) latestArticle._meta.parent = latestArticle.parent;


  const recentArticles = data.recentArticles.allArticles.edges.slice(1, 4);

  // defaults for the headings (if not supplied from cms)
  const recentHeading = [{"type":"heading2" ,"text": "Latest Articles", "spans":[]}];
  const moreHeading = [{"type":"heading2" ,"text": "More from Meniga", "spans":[]}];

  const articleCount = data.moreArticles.allArticles.totalCount;
  const pageCount = Math.ceil( (articleCount - 4) / 6 );

  const articleMeta = latestArticle ? (
      <ArticleMeta 
        show_author="true" 
        person={latestArticle.person}
        date={moment(latestArticle.date).format('DD MMM YYYY')}
      />
    ) : <></>


  const layoutParams = { 
    doc: doc.node,
    headerNav: data.header.navigation,
    secondaryNav: data.secondaryNav.navigation,
    footerNav: data.footer.navigation, 
    settings: data.settings.global 
  }


  return (
    <Layout {...layoutParams}>

      {latestArticle ? (
          <Block 
            className="o-block--content-block block-content_block featured-article"
            title_line="Under"
          >
            <div class="pure-g">
              <ContentBlock 
                column_direction="Default" 
                title={latestArticle.title}
                content={latestArticle.summary}
                image={latestArticle.image}                                       
                beforeClose={articleMeta}
                link_type="Title"
                link_text="Read more"
                link={{_linkType: 'Link.document', _meta: latestArticle._meta}}
              />
            </div>
          </Block>
        ) : <></>
      }


      {recentArticles.length > 0 ? (
          <Block 
            bg_color="#f9f9f7"
            title_line="Under"
            header_layout="Stacked"
            title={blogListing.recent_heading || recentHeading}
            block_type="recent-articles"
          >
            <div class="pure-g">
              {recentArticles.map((item, index) => (
                <ColumnBox
                  is_boxed="Yes"
                  layout="One Column - Two Column"
                  show_author="Yes"
                  item={item}
                  index={index}
                />
              ))}
            </div>
          </Block>
        ) : <></>
      }


      {moreArticles.length > 0 ? (
          <Block 
            title_line="Under"
            header_layout="Stacked"
            title={blogListing.more_heading || moreHeading}
            section_id="more-articles"
          >
            <div class="pure-g">
              {moreArticles.map((item, index) => (
                <ColumnBox 
                  is_boxed="No" 
                  show_author="Yes"
                  item={item} 
                  index={index}
                />
              ))}
            </div>

            {pageCount > 1 ? (
                <div class="react-paginate">
                  <ReactPaginate
                    previousLabel="Prev"
                    nextLabel="Next"
                    forcePage={forcePage}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                  />
                </div>
              ) : <></>}
          </Block>
        ) : <></>
      }
    </Layout>
  )
}
