import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Link as PrismicLink } from 'prismic-reactjs'

import Nav from 'react-bootstrap/Nav'
import { linkResolver } from '../utils/linkResolver'

export default class LinkWrapper extends Component {

    render() {

        let classes = this.props.className
        if ( this.props.isButton === 'true' || this.props.isButton === 'Yes' ) {
            classes += ' o-btn u-btn-primary'
            if ( this.props.color ) {
                classes += ' u-bg-color-' + this.props.color
            }
        } else {
            classes += ' u-unstyled-link'
            if ( this.props.color ) {
                classes += ' u-color-' + this.props.color
            }
        }

        if (this.props.link_style && this.props.link_style.indexOf('Inverted') !== -1) {
            classes += ' btn-inverted'
        }

        /*let colourMapping = {
            blue: '#80CEDF'
        }**/

        const headerNavTitles = ['Products', 'Our clients', 'Resources'];
        const isActive = navTitle => ({location}) => {
            const newClassName = { className: `${classes} nav-link`};
            const relevantPaths = {
                [headerNavTitles[0]]: ['/product', '/products', '/carbon-insight', '/smart-money-rules'],
                [headerNavTitles[1]]: ['/our-clients', '/story'],
                [headerNavTitles[2]]: ['/resources', '/download']
            };

            headerNavTitles.forEach(title => {
                if (title === navTitle && relevantPaths[navTitle].some(path => location.pathname.startsWith(path))) {
                    newClassName.className += ' selectedLink';
                }
            });
            return newClassName;
        }

        let link = <></>

        if ( this.props._meta ) 
            this.props._meta.link_type = 'Document'

        if ( this.props._linkType === 'Link.document' ) {
            const hash = this.props.anchor ? '#' + this.props.anchor : '';
            const standardProps = {
                to: PrismicLink.url({...this.props._meta, hello: true}, linkResolver) + hash,
                className: classes,
                style: this.props.style
            }
            const navProp = headerNavTitles.includes(this.props.children) ? {getProps: isActive(this.props.children)} : {activeClassName: "selectedLink"};

            link = this.props.navLink ?
                <Nav.Link as={Link} {...standardProps} {...navProp}>{this.props.children}</Nav.Link>
                : <Link {...standardProps}>{this.props.children}</Link>
        } else if ( this.props._linkType === 'Link.web' || this.props._linkType === 'Link.file' ) {

            {/*<Nav.Link as={Link} to={this.props.url} className={classes}>{this.props.children}</Nav.Link>*/}

            link = this.props.navLink ? 
                <a href={this.props.url} target={ this.props.target || '_blank' } className={classes + ' nav-link'} style={this.props.style}>{this.props.children}</a>
                : <a href={this.props.url} target={ this.props.target || '_blank' } className={classes} style={this.props.style}>{this.props.children}</a>
        }

        return link
    }

}