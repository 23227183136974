import React from 'react';
import './index.scss';


export default (props) => {
    let styles = { width: props.width, height: props.height, top: props.top, right: props.right, bottom: props.bottom, left: props.left}

    return (
        <div className={'o-shape' + (props.visibility && props.visibility !== 'All' ? ' u-visibility-' + props.visibility.toLowerCase() : '')} style={styles}>
            <img src={props.vector && props.vector.url} alt={props.vector && props.vector.alt} className="img-fluid"></img>
        </div>
    )
}
