import React from "react"
import { Elements } from "prismic-reactjs"
import AppDownloads from '../components/app-downloads'


const SocialButtons = () => {
  return (<AppDownloads osBased={true} className="o-btn u-btn-primary"></AppDownloads>);
}


const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
}


// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {
  let props = {};

  switch (type) {
    case "heading1":
      props = { className: "o-title" }
      return React.createElement("h1", propsWithUniqueKey(props, key), children)


    case Elements.paragraph:
      props = {className: 'paragraph-class'}
      return React.createElement('p', propsWithUniqueKey(props, key), children)


    case Elements.preformatted:
      let matchedShortcode = false;

      children.map((child, index) => {
        child.map((child2, index2) => {
          if ( child2.match(/\[app\]/) ) {
            matchedShortcode = true;
            children = <SocialButtons/>;
          }
        });
      });

      if ( matchedShortcode ) return React.createElement('div', propsWithUniqueKey({}, key), children);

      return React.createElement('pre', propsWithUniqueKey({}, key), children);


    // Return null to stick with the default behavior
    default:
      return null
  }
}


export default htmlSerializer
