import React from 'react';
import { StaticQuery, graphql } from 'gatsby';


const query = graphql`
  {  
    prismic {
      _allDocuments(type: "settings") {
        edges {
          node {
            ...on PRISMIC_Settings {
                app_store_link
                play_store_link
            }
          }
        }
      }
    }
  }
`


function getMobileOperatingSystem() {
    if ( typeof window === 'undefined' || typeof navigator === 'undefined' ) {
        return false
    }

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if ( /android/i.test(userAgent) ) {
        return "android"
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if ( /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream ) {
        return "ios"
    }

    return false
}


const AppStore  = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.9979 19.9208C22.6232 20.7864 22.1796 21.5833 21.6658 22.3159C20.9653 23.3146 20.3918 24.0059 19.9498 24.3897C19.2646 25.0199 18.5305 25.3426 17.7444 25.3609C17.18 25.3609 16.4994 25.2003 15.7072 24.8746C14.9123 24.5503 14.1819 24.3897 13.514 24.3897C12.8135 24.3897 12.0623 24.5503 11.2587 24.8746C10.454 25.2003 9.80564 25.3701 9.30995 25.3869C8.55611 25.419 7.80471 25.0871 7.05468 24.3897C6.57598 23.9722 5.97721 23.2564 5.25992 22.2424C4.49032 21.1596 3.8576 19.904 3.36192 18.4724C2.83106 16.9262 2.56494 15.4289 2.56494 13.9793C2.56494 12.3189 2.92374 10.8867 3.64241 9.68658C4.20722 8.72259 4.95862 7.96217 5.89906 7.40393C6.83949 6.8457 7.85564 6.56123 8.94993 6.54303C9.5487 6.54303 10.3339 6.72824 11.3097 7.09224C12.2827 7.45746 12.9074 7.64268 13.1814 7.64268C13.3861 7.64268 14.0802 7.42611 15.2568 6.99436C16.3694 6.59396 17.3085 6.42817 18.0778 6.49348C20.1624 6.66171 21.7285 7.48346 22.77 8.96393C20.9056 10.0936 19.9834 11.6757 20.0018 13.7054C20.0186 15.2864 20.5921 16.602 21.7193 17.6466C22.2301 18.1314 22.8006 18.5061 23.4353 18.7722C23.2976 19.1714 23.1524 19.5537 22.9979 19.9208ZM18.2169 1.1075C18.2169 2.34664 17.7642 3.50363 16.8619 4.57452C15.7729 5.8476 14.4558 6.58325 13.0275 6.46717C13.0093 6.31851 12.9987 6.16205 12.9987 5.99764C12.9987 4.80806 13.5166 3.53498 14.4362 2.49406C14.8954 1.96703 15.4793 1.52881 16.1874 1.17923C16.894 0.834866 17.5624 0.644423 18.1909 0.611816C18.2093 0.777467 18.2169 0.943133 18.2169 1.10748V1.1075Z" fill="#767676"/>
    </svg>
)


const PlayStore = () => (
    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.60183 0.969854L0 0V29L1.61552 28.0165L12.7598 14.5068L1.60183 0.969854Z" fill="#767676"/>
        <path d="M16.4977 9.97201L5.13428 3.10107L13.65 13.428L16.4977 9.97201Z" fill="#767676"/>
        <path d="M5.14795 25.8855L16.4976 19.0282L13.6499 15.5723L5.14795 25.8855Z" fill="#767676"/>
        <path d="M14.5396 14.5069L17.6884 18.318L23.9999 14.4932L17.6884 10.6821L14.5396 14.5069Z" fill="#767676"/>
    </svg>
)


const render = (props, data) => {
    const links = []

    let os = getMobileOperatingSystem()

    if ( ! props.osBased || props.osBased ) {
        links.push({'url': data.app_store_link, 'text': 'App store', 'icon' : <AppStore></AppStore>})
    }

    if ( ! props.osBased || props.osBased ) {
        links.push({'url': data.play_store_link, 'text': 'Play store', 'icon' : <PlayStore></PlayStore>})
    }

    return (
        <div class="app-downloads">
            {links.map(link => {
                let store = (<a href={link.url} target="_blank" class={props.className}>{link.icon} {link.text}</a>)
                if ( props.wrap === 'true' ) store = <div>{store}</div>
                return store
            })}
        </div>
    )
}


export default (props) => {
    return (
        <StaticQuery query={query} render={data => {
                let d = data.prismic._allDocuments.edges.slice(0, 1).pop().node
                return render(props, d)
            }}
        />
    )
}
