import React, { Component } from 'react';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import { Link as GatsbyLink } from 'gatsby';
import { linkResolver } from '../../utils/linkResolver';
import { getLink } from '../../utils/link';
import Reveal from 'react-reveal/Reveal';
import { getPureColumnClass, getPureColumnRemainderClass } from '../../utils/columns';

import Link from '../link1';
import './index.scss';


export default class DoubleColumn extends Component {

    render() {
        let layout = (<></>)
        var title = this.props.title

        if ( this.props.title && RichText.asText(this.props.title) ) {
            title = title.map(item => {
                let findex = item.text.indexOf('*');
                let sindex = item.text.indexOf('*', findex +1)

                if ( findex !== -1 && sindex !== -1 ) {
                    item.spans.push({
                        start: findex,
                        end: sindex-1,
                        type: "strong"
                    })

                    item.text = item.text.replace(/\*/g, '')
                }

                return item
            })
        }


        let contentColumn = (
            <div className={getPureColumnClass(1, this.props.content_column_width, '1-2') + ' content-column col-dir-' + this.props.column_direction.toLowerCase() }>
                <div class={'content-column-inner' + (this.props.primary && this.props.primary.title_line && this.props.primary.title_line !== 'None' ? ' title-line--' + this.props.primary.title_line.toLowerCase() : '')} data-list-style={this.props.primary && this.props.primary.list_style}>
                    {(this.props.title && RichText.asText(this.props.title))
                        || this.props.subtitle && RichText.asText(this.props.subtitle) ? (
                                <div class="o-header">
                                    {this.props.link && this.props.link_type && this.props.link_type.indexOf('Title') !== -1 ?
                                        <GatsbyLink to={getLink(this.props.link)}>{RichText.render(title)}</GatsbyLink>
                                        : RichText.render(title)
                                    }

                                    {RichText.render(this.props.subtitle)}
                                </div>
                        ) : <></>
                    }

                    <div className="o-content" style={{ width: this.props.content_width }}>
                        {RichText.render(this.props.content, linkResolver, htmlSerializer)}
                        {this.props.afterContent}
                    </div>

                    {this.props.link ? (
                            <Reveal effect="fadeInScale" delay={300} duration={600}>
                                <div style={{ display: 'inline-block' }}>
                                    <Link {...this.props.link} className="u-plain-link" isButton={ !this.props.link_style || (this.props.link_style && this.props.link_style.indexOf('Button') !== -1) ? "true" : "false"} color={this.props.link_color ? this.props.link_color.toLowerCase() : 'blue'} anchor={this.props.link_anchor}>{this.props.link_text}</Link> 
                                </div>
                            </Reveal>
                        ) : ''
                    }
                </div>
            </div>
        )

        let secondaryColumn = (
            <div class={getPureColumnRemainderClass(this.props.content_column_width) + ' secondary-column'}>
                {this.props.children}
            </div>
        )

        layout = ( this.props.column_direction === 'Default' || !this.props.column_direction ) ?
            <>{contentColumn}{secondaryColumn}</>
            : <>{secondaryColumn}{contentColumn}</>

        return (<>{layout}</>)
    }
}
