import React, { Component } from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import HeaderLayout from '../SectionHeader';
import Shape from '../Shape';
import './index.scss';


export default class Block extends Component {

    render() {
        let all = this.props.children
        let style = {}
        let containerStyle = {}
        let hasContainer = this.props.container
        let stylesMapping = [
            {key: 'text_color', local: 'color'},
            {key: 'min_height', local: 'minHeight'},
        ]

        stylesMapping.push({key: 'bg_color', local: 'backgroundColor'})        
        stylesMapping.map( (item, key) => style[item.local] = this.props[item.key])

        let classes = ['o-section', 'o-block']
        let classMapping = [
            {key: 'className'},
            {key: 'padding_top', callback: this.getPaddingClass, params: 'top', ignore: 'Medium'},
            {key: 'padding_bottom', callback: this.getPaddingClass, params: 'bottom', ignore: 'Medium'},
            {key: 'align_columns', callback: this.getAlignItemsClass, ignore: 'Center'}
        ]

        classMapping.forEach((item, key) => {
            if ( ! this.props[ item.key ] || (item.ignore && this.props[ item.key ] === item.ignore) ) return
            if ( item.callback ) return classes.push( item.callback( this.props[item.key], item.params ) )
            return classes.push( this.props[item.key] )
        })

        if ( this.props.bg_image ) {
            style.backgroundImage = this.props.bg_image.url
        }

        if ( this.props.block_type ) {
            classes.push('o-block-type--' + this.props.block_type)
        }

        if ( this.props.text_align ) {
            classes.push('text-align--' + this.props.text_align.toLowerCase() )
        }

        if ( this.props.element_color ) {
            style['--element-color'] = this.props.element_color
        }

        if ( this.props.bg_color ) {
            style['--bg-color'] = this.props.bg_color
        }

        if ( this.props.bg_cover === "Container" ) {
            style.backgroundColor = false
            containerStyle = { backgroundColor: this.props.bg_color }
        }

        if ( this.props.custom_classes ) {
            classes = classes.concat( this.props.custom_classes )
        }


        let headerLayout = <></>

        if ( this.props.header_layout 
            && ((this.props.title && RichText.asText(this.props.title))
            || (this.props.content && RichText.asText(this.props.content)) ) ) {
                headerLayout = (
                    <HeaderLayout 
                        layout={this.props.header_layout} 
                        title={this.props.title} 
                        content={this.props.content}
                        text_align={this.props.header_text_align}
                    />
                )
            }

        let shapes = this.props.art && this.props.art.body.filter( a => a.type === 'art' )
        let shapeElements = (shapes && shapes.length > 0 ) ? (
            <div class="shape-container">
              {(shapes || []).map((item, index) => (
                  <>{item.fields.map( shape => (
                    <Shape {...shape} key={index}></Shape>
                  ))}</>
              ))}
            </div>
        ) : <></>

        let content = (
            <>
                {headerLayout}
                {all}
            </>
        )

        if ( this.props.layout === 'Aligned' && this.props.block_type !== 'header' && this.props.block_type !== 'form' ) {
            content = (
                <div className="pure-g layout-aligned">
                    <div className="pure-u-1 pure-u-md-2-5">
                        {headerLayout}
                    </div>
                    <div className="pure-u-1 pure-u-md-3-5">
                        {all}
                    </div>
                </div>
            )
        }

        let extraLink = <></>
        if ( this.props.link_text === 'Request Demo' && ( this.props.block_type === 'columns' || this.props.block_type === 'video' ) ) {
            extraLink = <div class="text-center extra-link"><Link to={'/demo/digital-banking-products'} class="o-btn u-btn-primary">Request Demo</Link></div>
        }

        return (
            <section id={this.props.section_id} className={classes.join(' ')} data-index={this.props.index} style={style} data-layout={this.props.layout} data-bg-cover={this.props.bg_cover} data-line={this.props.title_line ? this.props.title_line.toLowerCase() : ''}>
                {shapeElements}
                <div style={containerStyle} className={hasContainer !== false ? 'o-container' :''}>
                    {content}
                </div>
                {extraLink}
            </section>
        )
    }

    getPaddingClass(padding, pos) {
        return 'u-padding-' + pos + '-' + padding.toLowerCase()
    }

    getAlignItemsClass(align) {
        return 'u-align-items-' + align.toLowerCase()
    }
}
