import React from 'react';
import { Link } from 'gatsby';
import { Link as PrismicLink, RichText } from 'prismic-reactjs';
import moment from 'moment';
import { linkResolver } from '../../utils/linkResolver';
import Image from '../image';
import Reveal from 'react-reveal/Reveal';


export default (props) => {
    props.item.node._meta.link_type = 'Document'

    if ( props.item.node.parent ) {
        props.item.node._meta = Object.assign(props.item.node._meta, { parent: props.item.node.parent })
    }


    let inner = (item, props) => (
        <>
            <div class="image-holder">
                <Image image={item.node.image}></Image>
            </div>

            <div class="text-content">
                <div>
                    <h3 className="o-title">{RichText.asText(item.node.title)}</h3>

                    {!props.show_summary || (props.show_summary && props.show_summary === 'Yes') ? (
                            <div className="o-content">
                                {RichText.render(item.node.summary)}
                            </div>
                        ) : <></>
                    }

                    <span className="u-plain-link">Read More</span>
                </div>


                {(props.show_author && props.show_author === 'Yes' && item.node.person) ? (
                        <div class="c-author-box">
                            {item.node.person.image ? (
                                    <div class="c-author-box--image" style={{ backgroundImage: `url(${item.node.person.image.url})` }}></div>
                                ) : <></>
                            }

                            <div>
                                <p class="c-author-box--author">{item.node.person.name}</p>
                                <p class="c-author-box--details">{moment(item.node.date).format('DD MMM YYYY')}</p>
                            </div>
                        </div>
                    )  : <></>
                }
            </div>
        </>
    )


    let layout = <></>
    let classes = []

    if ( props.is_boxed === "Yes" || props.is_boxed === true ) classes.push('is-boxed')


    layout = (
        <div key={props.index} className={'pure-u-1 pure-u-md-1-3'}>
            <Reveal effect="fadeInScale" duration={600} delay={200 + ((props.index-1)*100)}>
                <Link to={PrismicLink.url(props.item.node._meta, linkResolver)} class={'o-article ' + classes.join(' ')}>
                    {inner(props.item, props)}
                </Link>
            </Reveal>
        </div>
    )


    if ( props.layout === 'One Column - Two Column' ) {
        props.item.node._meta.link_type = 'Document'

        layout = (
            <div className="pure-u-1 pure-u-md-1-2 u-margin-y-2">
                <Reveal effect="fadeInScale" duration={600} delay={200 + ((props.index-1)*100)}>
                    <Link to={PrismicLink.url(props.item.node._meta, linkResolver)} key={props.index} className={'o-article standard ' + classes.join(' ')}>
                        {inner(props.item, props)}
                    </Link>
                </Reveal>
            </div>
        )

        if ( props.index === 0 ) {
            layout = (
                <div key={props.index} className={'pure-u-1'}>
                    <Reveal effect="fadeInScale" duration={600} delay={200 + ((props.index-1)*100)}>
                        <Link to={PrismicLink.url(props.item.node._meta, linkResolver)} class={'o-article box side-by-side ' + classes.join(' ')}>
                            {inner(props.item, props)}
                        </Link>
                    </Reveal>
                </div>
            )
        }
    }

    return layout
}
