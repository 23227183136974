import { graphql } from 'gatsby'

export const query = graphql`
    fragment Article on PRISMIC_ArticleConnectionConnection {
        edges {
            node {
                title
                date
                image
                time_to_read
                summary

                _meta {
                    tags
                    id
                    uid
                    lang
                    type
                }

                parent {
                    ... on PRISMIC_Blog_listing {
                        _meta {
                            id
                            uid
                        }
                    }
                }

                body {
                    ... on PRISMIC_ArticleBodyContent {
                        primary {
                            content
                        }
                    }
                }

                person {
                    _linkType

                    ... on PRISMIC_Person {
                        name
                        role
                        image
                    }
                }
            }
        }
    }
`
