import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import Image from '../image';
import DoubleColumn from '../DoubleColumn';
import './index.scss';


export default class TextImage extends Component {
    constructor(props) {
        super(props)
        this.state = { width: false }
        this.handleResize = this.handleResize.bind(this)
    }


    handleResize() {
        this.setState({width: window.innerWidth})
    }


    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }


    render() {  
        let style = {}
        if ( this.props.image_width ) {
            style.width = this.props.image_width
        }

        style.maxWidth = 1540 * (parseInt((this.props.image_width ? this.props.image_width : 42)) / 100)

        let layeredStyle = {}

        if ( this.props.primary && this.props.primary.image_size_tweak_xs && this.state.width && this.state.width < 768 ) {
            let opts = this.props.primary.image_size_tweak_xs.split(",")
            let offset = this.props.column_direction === "Default" ? "right" : "left"
            layeredStyle = {position: 'relative', width: opts[0], marginTop: opts[2], marginBottom: opts[3]}
            layeredStyle[ offset ] = opts[1]
        }

        let handledArt = <></>
        if ( this.props.primary && this.props.primary.art ) {
            handledArt = this.props.primary.art.body
            .filter(a => a.type === 'handled_art').map((item) => (
                item.fields.map(item => {
                    return <Image image={item.vector} style={{ width: item.width }}></Image>
                }
            )))
        }

        let ghostImageDimensions = { width: 0, height: 0 }

        if ( this.props.image && this.props.image.dimensions ) {
            ghostImageDimensions = { width: this.props.image.dimensions.width, height: this.props.image.dimensions.height } //this.props.ghostImageDimensions
        }

        let imageCustomWidth = this.props.image_width || '50%'

        let imageColumn = (
            <>
                <div class="layered-image" style={layeredStyle}>
                    <div class="art">{handledArt}</div>

                    <div class="image-wrapper" style={style}>
                        <Reveal effect="fadeInScale" delay={300} duration={600}>
                            <Image image={this.props.image}></Image>
                        </Reveal>
                    </div>

                    {this.props.primary && this.props.image && this.props.image.dimensions && this.props.image_position === 'Edge' ? <img className="ghost-image" src={"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + ghostImageDimensions.width + "' height='" + ghostImageDimensions.height + "'/%3E"} style={{ 'max-width': this.props.image_position === 'Edge' ? ((parseInt(imageCustomWidth) / 50) * 100 + '%') : '100%' }}></img> : <></>}
                </div>
            </>
        )

        if ( this.props.image_link ) {
            imageColumn = (
                <a href={this.props.image_link.url} target={(this.props.image_link._linkType === 'Link.web' ? '_blank' : '_self')}>
                    {imageColumn}
                </a>
            )
        }

        let layout = (
            <DoubleColumn {...this.props}>
                {imageColumn}
            </DoubleColumn>
        )

        return (<>{layout}</>)
    }
}
